@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap");

:root {
  --h: 60;
  --s: 2%;
  --l: 10%;
  --h-accent: 240;

  --green-10: hsla(120, 100%, 60%, 0.1);
  --green-50: hsla(120, 60%, 35%, 1);
  --green-100: hsla(120, 100%, 10%, 1);
  --light: #fff;
  --dark: #000;

  --primary-light: #fafbf6;
  --primary-dark: hsla(var(--h), var(--s), var(--l), 1);
  --primary-100: var(--primary-dark);
  --primary-80: hsla(var(--h), var(--s), var(--l), 0.8);
  --primary-70: hsla(var(--h), var(--s), var(--l), 0.7);
  --primary-60: hsla(var(--h), var(--s), var(--l), 0.6);
  --primary-50: hsla(var(--h), var(--s), var(--l), 0.5);
  --primary-40: hsla(var(--h), var(--s), var(--l), 0.4);
  --primary-30: hsla(var(--h), var(--s), var(--l), 0.3);
  --primary-20: hsla(var(--h), var(--s), var(--l), 0.2);
  --primary-10: hsla(var(--h), var(--s), var(--l), 0.1);
  --primary-05: hsla(var(--h), var(--s), var(--l), 0.05);
  --primary-03: hsla(var(--h), var(--s), var(--l), 0.03);

  --accent: hsl(var(--h-accent), 100%, 50%);

  --focus: deeppink;
  --captions: var(--secondary-70);

  --logo-family: "Montserrat", cursive;
  --body-family: "Roboto", sans-serif;
  --mono-family: "Roboto Mono", monospace;
  --body: var(--secondary-100);

  --heading-family: "Montserrat", serif;
  --heading: var(--secondary-100);

  --navbar-grad-01: var(--secondary-03);
  --navbar-grad-02: var(--secondary-03);
  --navbar-grad-03: var(--secondary-03);

  --secondary-03: hsla(var(--h-accent), 50%, 50%, 0.03);
  --secondary-05: hsla(var(--h-accent), 50%, 50%, 0.05);
  --secondary-10: hsla(var(--h-accent), 50%, 50%, 0);
  --secondary-20: hsla(var(--h-accent), 50%, 50%, 0.1);
  --secondary-25: hsla(var(--h-accent), 50%, 50%, 0.25);
  --secondary-30: hsla(var(--h-accent), 50%, 50%, 0.3);
  --secondary-40: hsla(var(--h-accent), 50%, 50%, 0.4);
  --secondary-50: hsla(var(--h-accent), 50%, 50%, 0.5);
  --secondary-60: hsla(var(--h-accent), 50%, 50%, 0.6);
  --secondary-70: hsla(var(--h-accent), 40%, 40%, 0.7);
  --secondary-80: hsla(var(--h-accent), 80%, 40%, 0.8);
  --secondary-90: hsla(var(--h-accent), 40%, 40%, 0.9);
  --secondary-100: hsla(var(--h-accent), 50%, 40%, 1);

  --surface-light: #f7f7f7;

  --text-light: #151514;
  --text-low-emphasis: var(--primary-50);

  --h-alert: 170;
  --alert-bg: hsla(var(--h-alert), 50%, 90%, 1);
  --alert-border: hsla(var(--h-alert), 50%, 70%, 1);
  --alert-cat: hsla(var(--h-alert), 50%, 30%, 1);
  --alert-text: hsla(var(--h-alert), 50%, 10%, 1);

  --inputLabel: var(--secondary-100);
  --inputValue: var(--primary-100);

  --button-primary: var(--accent);
  --button-child: blue;

  --button-save-bg: var(--green-50);
  --button-save-label: var(--light);

  --buttonSecondary-bg: var(--light);
  --buttonSecondary-label: var(--primary-80);
  --buttonSecondary-border: var(--primary-80);
  --buttonSecondary-borderWidth: 4px;

  --share-label: var(--light);
  --share-bg: var(--accent);
  --share-border: var(--light);
  /*SkipTo Theme Variables*/

  --skipToIcon: "\f11c";
  --skipToMenuTextColor: var(--primary-100);
  --skipToButtonBackgroundColor: var(--surface-light);
  --skipToButtonBorderColor: var(--primary-100);
  --skipToMenuBackgroundColor: var(--surface-light);
  --skipToFocusBorderColor: var(--primary-40);
  --skipToMenuitemFocusBackgroundColor: var(--focus);
  --skipToMenuitemFocusTextColor: var(--primary-light);

  font-size: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: var(--focus) solid 4px;
  outline-offset: 4px;
  border-radius: 4px;
}

body {
  overflow-x: hidden;
  background-color: var(--secondary-100);
  background-position: top 0px center;
  background-repeat: no-repeat;
  background-image: radial-gradient(
    50% 50% at top center,
    var(--secondary-80),
    var(--secondary-100)
  );
  background-size: 120% 96px, 10% 20px;
}

body:has(#root > div.exportApp) {
  overflow-x: hidden;
  background-color: #f7f7f7;
  background-position: top 0px center;
  background-repeat: no-repeat;
  background-image: radial-gradient(
    50% 50% at top center,
    var(--dark),
    var(--dark)
  );
  background-size: 120% 96px, 10% 20px;
}

body {
  font-family: var(--body-family);
  font-weight: 400;
  max-width: 1024px;
  margin: 0 auto;
  background-color: var(--surface-light);
  color: var(--body);
  margin-bottom: 2rem;
}

header {
  height: 6rem;
}

/** SkipTo Styles **/

#skipToHere {
  display: inline;
  border: 1px solid var(--skipToButtonBorderColor);
  height: 100%;
  width: 40px;
}
.nolatin-skipTo button:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 300;
  content: var(--skipToIcon);
}

.nolatin-skipTo [role="menu"],
.nolatin-skipTo button {
  font-size: 100% !important;
  border: 1px solid var(--skipToButtonBorderColor) !important;
}

.nolatin-skipTo button:focus {
  outline: 4px solid var(--focus) !important;
}

.skip-to .skip-to-tooltip {
  color: #fff !important;
  background-color: var(--secondary-100) !important;
  border: 1px solid #fff !important;
  padding: 0 0.5rem !important;
  box-shadow: 2px 2px 8px var(--secondary-100) !important;
}

a:link,
a:visited {
  color: var(--accent);
}

a:hover,
a:active {
  color: var(--focus);
  background-color: var(--light);
  text-decoration: none;
}

#nolatin-logo {
  display: block;
  font-family: var(--logo-family);
  font-weight: 900;
  color: hsla(328, 100%, 54%, 0.9);
  font-size: 2.5rem;
  height: 4rem;
  line-height: 4rem;
  padding: 0 0.75rem;
  position: relative;
  left: -0.5rem;
  text-decoration: none;
}

#nolatin-logo:hover,
#nolatin-logo:active {
  background-color: transparent;
}

.main {
  display: block;
  padding: 0 1rem;
}

.back-row a {
  margin: auto 1rem;
  margin-left: 0;
}

.hero {
  display: block;
  padding: 2rem 2rem;
  background-color: var(--secondary-20);
  margin-top: 40px;
  border-radius: 16px;
}

footer .hero {
  margin: 1rem;
  margin-top: 8rem;
}

.hero h1,
.hero h2,
.hero p {
  background-color: transparent;
  line-height: auto;
  padding-left: 0;
  border-left: none;
}

.hero h1,
.hero h2 {
  font-size: 2rem;
  color: var(--secondary-100);
  margin-top: 1rem;
  font-weight: 700;
}
.hero p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--secondary-100);
}
.hero a:focus {
  outline-color: var(--focus);
}

.hero [role="button"] {
  margin: auto;
  color: var(--light);
  background-color: var(--secondary-100);
  border-radius: 2rem;
}

.hero a:hover {
  color: var(--light);
  background-color: var(--secondary-90);
}

.bad-ipsum {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-style: wavy;
  text-decoration-color: var(--focus);
  -webkit-text-fill-color: var(--focus);
  cursor: not-allowed;
}

#nolatin-logo .bad-ipsum {
  color: var(--light);
  -webkit-text-fill-color: var(--light);
}

p {
  margin-bottom: 1rem;
  line-height: 145%;
}

ul,
ol {
  display: block;
  margin: 0.5rem 0 1rem 2rem;
}

li {
  margin-bottom: 1rem;
  line-height: 125%;
}

.post-content ul {
  display: inline-block;
  margin: 0 0 0 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--body-family);
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  color: var(--heading);
  line-height: normal;
  padding: 0.5rem 0rem 0.5rem;
  border-bottom: 4px solid var(--secondary-20);
}

h1,
h2,
h3 {
  /* margin-bottom: 1rem; */
  margin: 2rem 0 1rem 0;
}

h1 {
  font-weight: 900;
  font-family: var(--heading-family);
}

.menu-popover a {
  margin: 0.5rem 1rem;
  font-weight: 500;
}

img.landing {
  margin-top: 2rem;
  border: 1rem solid var(--secondary-20);
  border-width: 1rem 0;
  display: cover;
  width: calc(100% + 2rem);
  position: relative;
  left: -1rem;
  right: -2rem;
}

cite {
  font-style: normal;
}

/* How to page styles */

.post-container {
  display: block;
  background-color: var(--light);
  margin: 1rem 0;
  padding: 1.5rem 1rem 0 1rem;
  overflow: auto;
  border: 4px solid var(--secondary-20);
  border-radius: 1rem;
  color: var(--secondary-90);
}

.post-container h1,
.post-container h2,
.post-container h3 {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-top: auto;
}

.credits .post-title {
  font-weight: 800;
  color: var(--secondary-90);
}

.credits [lang="pt"] {
  color: green;
}

.credits [lang="ru"] {
  color: firebrick;
}

.credits [lang="it"] {
  color: green;
}

.credits [lang="nl"] {
  color: royalblue;
}

.credits [lang="es"] {
  color: goldenrod;
}
.post-thumb {
  width: auto;
  max-width: 320px;
  padding: 0 1rem 0 0;
  height: auto;
  float: left;
}
/* How to styles end */

button {
  font-family: var(--body-family);
  font-weight: 600;
}

label {
  font-family: var(--body-family);
  color: var(--inputLabel);
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: block;
  margin: 1rem 0 0.25rem;
}
input,
textarea {
  margin-bottom: 0.75rem;
  background-color: #fff;
  border: 3px solid var(--secondary-80);
  border-radius: 4px;
  padding: 8px;
  font-family: var(--body-family);
  color: var(--inputValue);
  font-size: 1rem;
  width: 100%;
}

select {
  /* styling */
  background-color: white;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* new look */
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--secondary-80) 50%
    ),
    linear-gradient(135deg, var(--secondary-80) 50%, transparent 50%),
    linear-gradient(to right, var(--secondary-80), var(--secondary-80));
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.site-footer {
  position: relative;
  bottom: 0;
  display: block;
  height: auto;
  padding: 2rem;
  margin: 2rem 1rem 0;
  border-radius: 1rem;
  background-color: var(--secondary-20);
  text-align: center;
}

.site-footer h2 {
  color: var(--secondary-100);

  background-color: transparent;
}

.site-footer li {
  display: inline-block;
  margin: 0.5rem;
}
.site-footer a {
  padding: 4px;
  line-height: 2rem;
}

.post-footer {
  text-align: center;
  padding: 0.25rem;
}

.post-footer p {
  line-height: 3rem;
  font-size: 0.95rem !important;
}

.site-cta [role="button"] {
  margin: auto;
  color: var(--light);
  background-color: var(--secondary-100);
  border-radius: 2rem;
}
.site-cta [role="button"]:hover {
  color: var(--light);
  background-color: var(--secondary-90);
}

.card-toolbar button {
  background-color: var(--light) !important;
  margin-left: 0.5rem;
  border: 2px solid var(--secondary-20);
  border-radius: 0.5rem !important;
}

.toolbar {
  display: block;
  width: auto;
  margin: 0;
  border-radius: 1rem;
  padding: 0.25rem;
}

.toolbar a {
  margin-top: 0;
}

.toolbar [role="button"] {
}

h1 + .instructions {
  position: relative;
  top: calc(-1rem - 3px);
  margin: 0;
}

.instructions,
.alert-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  padding: 1rem 1rem 0;
  /* border-left: 0.25rem solid var(--secondary-20); */
  background-color: var(--secondary-20);
  border-radius: 0 0 1rem 1rem;
}

.instructions h2 {
  color: var(--secondary-100);
  font-weight: 700;
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  background-color: transparent;
  padding: 0;
  border: none;
}

figcaption {
  font-family: var(--mono-family);
  font-size: 0.75rem;
  color: var(--captions);
  margin-top: 8px;
  margin-bottom: 3rem;
  letter-spacing: normal;
  line-height: 150%;
}

blockquote {
  display: block;
  margin: 1rem 0;
  padding: 1rem;
  width: 100%;
  font-family: var(--body-family);
  /* font-style: italic; */
  letter-spacing: -0.025rem;
  line-height: 150%;
  font-size: 1.5rem;
  border-left: 3px dotted var(--secondary-30);
  background-color: var(--light);
}

blockquote h1,
blockquote h2,
blockquote h3 {
  color: var(--secondary-90);
  margin: 0 0 1rem;
  font-family: var(--heading-family);
}

.subtitle {
  letter-spacing: -0.025rem;
  line-height: 150%;
  font-size: 1.25rem;
}

/* Modal */
.scrollMe {
  display: block;
  overflow-y: scroll !important;
  max-height: 80vh;
  padding: 0.5rem;
}

hr {
  text-align: center;
  display: block;
  height: 0.25rem;
  margin: 3rem 0;
  background: linear-gradient(
    to right,
    var(--secondary-10),
    var(--secondary-40),
    var(--secondary-10)
  );
  border: none;
}

.page-box {
  background-color: var(--light);
  padding: 0 0.5rem;
}

.page-box ul,
ol {
  margin-left: 0;
}

.page-end {
  background: none;
  background-color: var(--light);
  height: 3rem;
  margin: 0;
  border: none;
  border-bottom: 8px solid var(--secondary-40);
}

/*
Prototyping Styles
*/

.proto-mgt {
  display: block;
}

.proto-mgt-header {
  margin-top: 1rem;
  padding: 1rem;
  border-top: 8px solid var(--secondary-40);
  background-color: var(--light);
}

.proto-mgt-list {
  margin-left: auto;
}

.pages-button {
  margin-top: 1rem;
}

.row-add-headline {
  align-self: center;
}

.proto-mgt h2,
.proto-mgt-desc {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: var(--secondary-100);
  background-color: transparent;
  border: none;
}

.proto-mgt button,
.proto-mgt [role="button"] {
  background-color: var(--button-primary);
  color: var(--light);
  font-weight: bold;

  border-radius: 2rem;
}

.proto-mgt [role="button"] {
  background-color: var(--button-primary);
  color: var(--light);
  font-weight: bold;
  margin: auto;
}

.preview [role="button"] {
  border-radius: 1rem;
  width: auto;
  text-align: right;
  padding-right: 0;
}

.preview svg {
  margin-left: 0.5rem;
}

.card-container {
  background-color: var(--light);
}

.card-container .pagecard-title {
  color: var(--accent);
  margin: auto 0;
  font-weight: 700;
}
.pagecard-label,
.proto-mgt-cat {
  display: block;
  padding: 0;
  margin: 1rem 0 1rem 0;
  color: var(--secondary-80);
}

.card-container button {
  margin: auto 0.25rem;
}

.card-container:first-of-type .pagecard-title:before,
span.play {
  font-family: "Font Awesome 5 Free";
  content: "\f144";
  display: inline;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.exportApp {
  padding: 0;
  margin: 0;
  background: #f7f7f7;
  padding-bottom: 80px;
}

.exportApp main {
  background: none;
}

.exportApp main,
.exportApp header {
  padding: 0;
}
.exportApp header {
  height: auto;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.exportApp footer,
.exportApp section,
.exportApp article,
.exportApp aside,
.exportApp nav,
.exportApp main header,
.exportApp form,
.exportApp [role="alert"],
.exportApp [role="banner"],
.exportApp [role="main"],
.exportApp [role="search"],
.exportApp [role="contentinfo"],
.exportApp [role="complementary"] {
  background-color: var(--light);
  border: 4px solid var(--primary-10);
  border-radius: 16px;
  margin: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
}

.exportApp header nav {
  background-color: black;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.exportApp header nav button {
  margin: auto;
}

.page-description {
  display: block;
  padding: 0.125rem !important;
  background-color: #f7f7d1;
  margin: 0 0.5rem;
  outline: 0.5rem solid #f7f7d1;
}

.exportApp [role="alert"] {
  background-color: var(--alert-bg);
  border: 4px solid var(--alert-border);
  margin: 1rem;
  margin-bottom: 1rem;
}

.exportApp [role="alert"] h2,
.exportApp [role="alert"] h3,
.exportApp [role="alert"] h4,
.exportApp [role="alert"] h5,
.exportApp [role="alert"] h6,
.exportApp [role="alert"] p {
  color: var(--alert-text);
}

.exportApp [role="alert"] h2::before,
.exportApp [role="alert"] h3::before,
.exportApp [role="alert"] h4::before,
.exportApp [role="alert"] h5::before,
.exportApp [role="alert"] h6::before {
  display: block;
  content: "Alert Message: \A";
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  line-height: 1rem;
  color: var(--alert-cat);
  margin: 0;
  white-space: pre-wrap;
}

.exportApp h1,
.exportApp h2,
.exportApp h3,
.exportApp h4,
.exportApp h5,
.exportApp h6 {
  font-family: "Roboto", Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  font-weight: bold;
  /* line-height: 200%; */
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  color: var(--primary-80);
  border: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
}

.exportApp h1 {
  font-size: 1.5rem;
  /* line-height: 100%; */
  color: #fff;
}
.exportApp h2 {
  font-size: 1.5em;
}
.exportApp h3 {
  font-size: 1.17em;
}
.exportApp h4 {
  font-size: 1em;
}
.exportApp h5 {
  font-size: 0.83em;
}
.exportApp h6 {
  font-size: 0.67em;
}

.exportApp .proto-actions button {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  border: 2px solid var(--share-border);
  border-radius: 8px;
  background-color: var(--share-bg);
  /* hiding export */
  /* display: none; */
}
.exportApp p,
.exportApp label,
.exportApp li {
  font-size: 1rem !important;
  line-height: normal;
  color: #000;
}

.exportApp li {
  line-height: 125%;
}

.exportApp ol li {
  margin-left: 1.5rem;
}

.exportApp header {
  background-color: var(--dark);
  height: 6rem;
}

.exportApp main header {
  background-color: var(--light);
  height: auto;
}

.exportApp label {
  font-weight: bold;
  margin-bottom: 0.75rem;
}
.exportApp input {
  border-color: var(--dark);
  margin-bottom: 1rem;
}

.exportApp select {
  /* styling */
  background-color: white;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5rem 3.5rem 0.5rem 0.5rem;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* new look */
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--primary-80) 50%
    ),
    linear-gradient(135deg, var(--primary-80) 50%, transparent 50%),
    linear-gradient(to right, var(--primary-80), var(--primary-80));
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border: 3px solid var(--primary-80);
}

.exportApp button,
.exportApp [role="button"] {
  background-color: var(--dark);
  color: var(--light);
  border: none;
  margin: auto;
  width: auto;
  /* max-width: fit-content; */
  /* padding: 1rem 4rem; */
}

.exportApp *:focus {
  outline: var(--focus) solid 8px;
  outline-offset: 4px;
  border-radius: 4px;
}

.friendlyName {
  color: var(--focus);
  font-weight: 500;
}

.MuiModal-root {
  max-width: 768px;
  margin: auto;
}

.MuiPopover-root {
  max-width: none;
  margin: 0;
}
